var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSpinner
    ? _c("spinner-custom")
    : _c(
        "div",
        [
          _c("el-row", [
            _c(
              "div",
              {
                staticClass: "header-row",
                staticStyle: { "margin-bottom": "20px" },
              },
              [
                _c("div", { staticClass: "title-text" }, [
                  _vm._v("1. Thông tin quỹ tiền mặt"),
                ]),
                _c(
                  "span",
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: { "margin-right": "20px" },
                        on: {
                          change: function ($event) {
                            return _vm.changeTypeMethod()
                          },
                        },
                        model: {
                          value: _vm.dataSearchCashBook.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataSearchCashBook, "type", $$v)
                          },
                          expression: "dataSearchCashBook.type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "month" } }, [
                          _vm._v("Xem theo tháng"),
                        ]),
                        _c("el-radio", { attrs: { label: "date" } }, [
                          _vm._v("Xem theo ngày"),
                        ]),
                      ],
                      1
                    ),
                    _vm.dataSearchCashBook.type == "month"
                      ? _c(
                          "el-select",
                          {
                            staticStyle: { width: "120px" },
                            attrs: { clearable: "", placeholder: "Chọn tháng" },
                            on: {
                              change: function ($event) {
                                return _vm.searchCashBookStatisticalMethod()
                              },
                            },
                            model: {
                              value: _vm.dataSearchCashBook.month,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataSearchCashBook, "month", $$v)
                              },
                              expression: "dataSearchCashBook.month",
                            },
                          },
                          _vm._l(_vm.monthList, function (item) {
                            return _c("el-option", {
                              key: item.key,
                              attrs: { label: item.value, value: item.key },
                            })
                          }),
                          1
                        )
                      : _vm.dataSearchCashBook.type == "date"
                      ? _c("el-date-picker", {
                          staticClass: "input-common",
                          staticStyle: { width: "265px" },
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            format: "dd-MM-yyyy",
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "-",
                            "start-placeholder": "Ngày bắt đầu",
                            "end-placeholder": "Ngày kết thúc",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeDateCashBookMethod()
                            },
                          },
                          model: {
                            value: _vm.dataSearchCashBook.dateList,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSearchCashBook, "dateList", $$v)
                            },
                            expression: "dataSearchCashBook.dateList",
                          },
                        })
                      : _vm._e(),
                    _c("el-date-picker", {
                      staticClass: "input-common",
                      staticStyle: { width: "100px", "margin-left": "10px" },
                      attrs: {
                        clearable: false,
                        type: "year",
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.searchCashBookStatisticalMethod()
                        },
                      },
                      model: {
                        value: _vm.dataSearchCashBook.year,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearchCashBook, "year", $$v)
                        },
                        expression: "dataSearchCashBook.year",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              [
                _c(
                  "el-col",
                  {
                    staticStyle: { background: "#409eff" },
                    attrs: { span: 5 },
                  },
                  [
                    _vm._v("\n        Dư đầu kỳ:\n        "),
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("formatCurrencyNew")(
                              _vm.cashBookData.moneyStart
                            )
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      background: "#67c23a",
                      "margin-left": "10px",
                    },
                    attrs: { span: 4 },
                  },
                  [
                    _vm._v("\n        Tổng thu:\n        "),
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("formatCurrencyNew")(
                              _vm.cashBookData.moneyIn
                            )
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      background: "#f56c6c",
                      "margin-left": "10px",
                    },
                    attrs: { span: 4 },
                  },
                  [
                    _vm._v("\n        Tổng chi:\n        "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatCurrencyNew")(_vm.cashBookData.moneyOut)
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      background: "#e6a23c",
                      "margin-left": "10px",
                    },
                    attrs: { span: 5 },
                  },
                  [
                    _vm._v("\n        Thu-Chi:\n        "),
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("formatCurrencyNew")(
                              _vm.cashBookData.moneyIn -
                                _vm.cashBookData.moneyOut
                            )
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      background: "#409eff",
                      "margin-left": "10px",
                    },
                    attrs: { span: 5 },
                  },
                  [
                    _vm._v("\n        Dư cuối kỳ:\n        "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatCurrencyNew")(_vm.cashBookData.moneyEnd)
                        )
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("br"),
          _c("el-row", [
            _c(
              "div",
              { staticClass: "header-row" },
              [
                _c("div", { staticClass: "title-text" }, [
                  _vm._v("2. Thu chi nội bộ"),
                ]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "120px", "margin-right": "5px" },
                    on: {
                      change: function ($event) {
                        return _vm.changeMonthInternalMethod()
                      },
                    },
                    model: {
                      value: _vm.dataSearchInternal.startMonth,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearchInternal, "startMonth", $$v)
                      },
                      expression: "dataSearchInternal.startMonth",
                    },
                  },
                  _vm._l(_vm.startMonthInternalList, function (item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.value, value: item.key },
                    })
                  }),
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "120px", "margin-right": "5px" },
                    on: {
                      change: function ($event) {
                        return _vm.changeMonthInternalMethod()
                      },
                    },
                    model: {
                      value: _vm.dataSearchInternal.endMonth,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearchInternal, "endMonth", $$v)
                      },
                      expression: "dataSearchInternal.endMonth",
                    },
                  },
                  _vm._l(_vm.endMonthInternalList, function (item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.value, value: item.key },
                    })
                  }),
                  1
                ),
                _c("el-date-picker", {
                  staticClass: "input-common",
                  staticStyle: { width: "100px" },
                  attrs: {
                    clearable: false,
                    type: "year",
                    "value-format": "yyyy-MM-dd",
                    format: "yyyy",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.searchStatisticalInternalMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearchInternal.year,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearchInternal, "year", $$v)
                    },
                    expression: "dataSearchInternal.year",
                  },
                }),
              ],
              1
            ),
            _c("br"),
            _c("div", [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "10px 10px" },
                          attrs: { span: 4 },
                        },
                        [_c("div", [_vm._v("Thu chi")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row3-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Tổng thu (1)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row3-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Tổng chi (2)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row3-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("(1) - (2)")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "row11 align-row", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.internalData.moneyIn
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.internalData.moneyOut
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.internalData.moneyIn -
                                      _vm.internalData.moneyOut
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("br"),
          _c("el-row", [
            _c(
              "div",
              { staticClass: "header-row" },
              [
                _c("div", { staticClass: "title-text" }, [
                  _vm._v("3. Thông tin học phí"),
                ]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "120px", "margin-right": "5px" },
                    on: {
                      change: function ($event) {
                        return _vm.changeMonthFeesMethod()
                      },
                    },
                    model: {
                      value: _vm.dataSearchFees.startMonth,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearchFees, "startMonth", $$v)
                      },
                      expression: "dataSearchFees.startMonth",
                    },
                  },
                  _vm._l(_vm.startMonthFeesList, function (item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.value, value: item.key },
                    })
                  }),
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "120px", "margin-right": "5px" },
                    on: {
                      change: function ($event) {
                        return _vm.changeMonthFeesMethod()
                      },
                    },
                    model: {
                      value: _vm.dataSearchFees.endMonth,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearchFees, "endMonth", $$v)
                      },
                      expression: "dataSearchFees.endMonth",
                    },
                  },
                  _vm._l(_vm.endMonthFeesList, function (item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.value, value: item.key },
                    })
                  }),
                  1
                ),
                _c("el-date-picker", {
                  staticClass: "input-common",
                  staticStyle: { width: "100px" },
                  attrs: {
                    clearable: false,
                    type: "year",
                    "value-format": "yyyy-MM-dd",
                    format: "yyyy",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.searchStatisticalFeesMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearchFees.year,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearchFees, "year", $$v)
                    },
                    expression: "dataSearchFees.year",
                  },
                }),
              ],
              1
            ),
            _c("br"),
            _c("div", [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "10px 10px" },
                          attrs: { span: 4 },
                        },
                        [_c("div", [_vm._v("Khoản thu")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row1-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Tổng phải thu (1)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row1-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Tổng đã thu (3)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row1-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Còn lại phải thu (5)")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "row11 align-row", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.feesData.moneyTotalIn
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.feesData.moneyTotalPaidIn
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.feesData.moneyTotalRemainIn
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "10px 10px" },
                          attrs: { span: 4 },
                        },
                        [_c("div", [_vm._v("Khoản chi")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row2-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Tổng phải chi (2)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row2-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Tổng đã chi (4)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row2-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Còn lại phải chi (6)")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "row11 align-row", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.feesData.moneyTotalOut
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.feesData.moneyTotalPaidOut
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.feesData.moneyTotalRemainOut
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "10px 10px" },
                          attrs: { span: 4 },
                        },
                        [_c("div", [_vm._v("Tổng hợp")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row3-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("(1) - (2)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row3-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("(3) - (4)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row3-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("(5) - (6)")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "row11 align-row", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.feesData.moneyTotalInOut
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.feesData.moneyTotalInOutPaid
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.feesData.moneyTotalInOutRemain
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "10px 10px" },
                          attrs: { span: 4 },
                        },
                        [_c("div", [_vm._v("Thống kê")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row4-background",
                          staticStyle: { "font-weight": "normal" },
                          attrs: { span: 9 },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              Số học sinh chưa hoàn thành hóa đơn: " +
                                _vm._s(_vm.feesData.kidsNumber) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row4-background",
                          staticStyle: { "font-weight": "normal" },
                          attrs: { span: 9 },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              Số hóa đơn chưa hoàn thành: " +
                                _vm._s(_vm.feesData.orderNumber) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("br"),
          _c("el-row", [
            _c(
              "div",
              { staticClass: "header-row" },
              [
                _c("div", { staticClass: "title-text" }, [
                  _vm._v("4. Thông tin công lương"),
                ]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "120px", "margin-right": "5px" },
                    on: {
                      change: function ($event) {
                        return _vm.changeMonthSalaryMethod()
                      },
                    },
                    model: {
                      value: _vm.dataSearchSalary.startMonth,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearchSalary, "startMonth", $$v)
                      },
                      expression: "dataSearchSalary.startMonth",
                    },
                  },
                  _vm._l(_vm.startMonthSalaryList, function (item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.value, value: item.key },
                    })
                  }),
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "120px", "margin-right": "5px" },
                    on: {
                      change: function ($event) {
                        return _vm.changeMonthSalaryMethod()
                      },
                    },
                    model: {
                      value: _vm.dataSearchSalary.endMonth,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearchSalary, "endMonth", $$v)
                      },
                      expression: "dataSearchSalary.endMonth",
                    },
                  },
                  _vm._l(_vm.endMonthSalaryList, function (item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.value, value: item.key },
                    })
                  }),
                  1
                ),
                _c("el-date-picker", {
                  staticClass: "input-common",
                  staticStyle: { width: "100px" },
                  attrs: {
                    clearable: false,
                    type: "year",
                    "value-format": "yyyy-MM-dd",
                    format: "yyyy",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.searchStatisticalSalaryMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearchSalary.year,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearchSalary, "year", $$v)
                    },
                    expression: "dataSearchSalary.year",
                  },
                }),
              ],
              1
            ),
            _c("br"),
            _c("div", [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "10px 10px" },
                          attrs: { span: 4 },
                        },
                        [_c("div", [_vm._v("Khoản chi")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row2-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Tổng phải chi (1)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row2-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Tổng đã chi (3)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row2-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Còn lại phải chi (5)")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "row11 align-row", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.salaryData.moneyTotalOut
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.salaryData.moneyTotalPaidOut
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.salaryData.moneyTotalRemainOut
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "10px 10px" },
                          attrs: { span: 4 },
                        },
                        [_c("div", [_vm._v("Khoản thu")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row1-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Tổng phải thu (2)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row1-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Tổng đã thu (4)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row1-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("Còn lại phải thu (5)")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "row11 align-row", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.salaryData.moneyTotalIn
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.salaryData.moneyTotalPaidIn
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.salaryData.moneyTotalRemainIn
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "10px 10px" },
                          attrs: { span: 4 },
                        },
                        [_c("div", [_vm._v("Tổng hợp")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row3-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("(1) - (2)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row3-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("(3) - (4)")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row3-background",
                          attrs: { span: 6 },
                        },
                        [_c("div", [_vm._v("(5) - (6)")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "row11 align-row", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.salaryData.moneyTotalInOut
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.salaryData.moneyTotalInOutPaid
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "row11", attrs: { span: 6 } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.salaryData.moneyTotalInOutRemain
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "10px 10px" },
                          attrs: { span: 4 },
                        },
                        [_c("div", [_vm._v("Thống kê")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row4-background",
                          staticStyle: { "font-weight": "normal" },
                          attrs: { span: 9 },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              Số nhân sự chưa hoàn thành hóa đơn:\n              " +
                                _vm._s(_vm.salaryData.kidsNumber) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row-header row4-background",
                          staticStyle: { "font-weight": "normal" },
                          attrs: { span: 9 },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              Số hóa đơn chưa hoàn thành: " +
                                _vm._s(_vm.salaryData.orderNumber) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("br"),
          _c("br"),
          _c("br"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }